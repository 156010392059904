import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Button from '@material-ui/core/Button';
import { downloadPdf } from '../../../helpers/download';

function isFullFeaturedBrowser() {
  const userAgent = navigator.userAgent || window.opera;
  return /Chrome|Safari|Firefox|Edge/.test(userAgent) && !/wv|iPhone|iPad/.test(userAgent);
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: '#4CAD00',
    '&:hover': {
      backgroundColor: "#D7FCBA",
    },
  },
}))(Button);


export default ({ fileUrl, filename }) => {

  const handleClick = () => {
    if (isFullFeaturedBrowser()) {
      downloadPdf(fileUrl, filename);
    } else {
      window.open(fileUrl, '_blank').focus();
    }
  };

  return (
    <ColorButton
      startIcon={<ReceiptIcon />}
      variant="contained"
      style={{ width: '100%' }}
      onClick={handleClick}
    >
      ใบเสร็จ
    </ColorButton>
  );
}
