import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParams } from '../../helpers/location';
import { getOrder } from '../../actions/orders';
import { createLoadingSelector } from '../../reducers/api';
import { renderAppointmentDateTime } from '../../helpers/date';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Layout from '../../components/Layout';
import CheckoutItems from '../../components/CheckoutItems';
import CheckoutSummary from '../../components/CheckoutSummary';
import SkeletonLoader from '../../components/SkeletonLoader';
import ReceiptDownloadButton from '../../components/Button/ReceiptDownloadButton';

const useStyles = makeStyles(theme => ({
  orderId: {
    marginTop: -15
  },
  status_waiting_paid: {
    marginLeft: 30,
    color: theme.palette.error.main,
  },
  status_paid: {
    marginLeft: 30,
    color: theme.palette.secondary.main,
  },
}));

const paymentStatus = {
  'waiting_paid': 'รอการชำระ',
  'paid': 'ชำระแล้ว',
  'finished': 'ตรวจเสร็จแล้ว'
}

export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = getUrlParams(location.search);
  const isLoading = useSelector(createLoadingSelector(['order/get']));
  const order = useSelector(state => state.order.byId[query.id]);

  React.useEffect(() => {
    if (query.id) {
      dispatch(getOrder(query.id));
    }
  }, [query.id]);

  if (!order) return (
    <Layout hideAppBar>
      <SkeletonLoader />
    </Layout>
  );

  const { payment_status, delivery_company } = order;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ประวัติซื้อยา - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <h2>ประวัติซื้อยา</h2>
        <p className={classes.orderId}>
          <small># {order.order_id}</small>
          <small className={classes[`status_${payment_status}`]}>{paymentStatus[payment_status]}</small>
          <br/>
          <small>{renderAppointmentDateTime(order.created)}</small>
        </p>
        {
          delivery_company && (
            <>
              <h3>จัดส่งโดย {delivery_company.company.name}</h3>
              <p>
                {delivery_company.tracking_number} <a href={`${delivery_company.url}`}>กดที่นี่เพื่อดูสถานะ</a>
              </p>
            </>
          )
        }
        <h3>รายการสินค้า</h3>
        <CheckoutItems order={order} readOnly />
        <CheckoutSummary deliveryTitle="ค่าส่ง" summary={order} />
        {order.receipt &&
          <Box mt={1}>
            <ReceiptDownloadButton
              fileUrl={order.receipt.fileUrl}
              filename={order.receipt.id} />
          </Box>
        }
      </Box>
    </Layout>
  );
}

